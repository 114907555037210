<template>
  <div>
    <div class="mb-1">
      <div class="row">
        <v-select class="col-lg-3 col-md-3 mt-1"
          placeholder="Groups"
          :options="uniqueGroups"
          id="id" label="name"
          v-model="orderFilters.groups"
        ></v-select>
        <v-select class="col-lg-3 col-md-3 mt-1"
          placeholder="Language"
          :options="languages"
          v-model="orderFilters.languages"
        ></v-select>
        <div class="col-lg-3 col-md-3 mt-1 display-flex">
          <div class="col-lg-4 col-md-4 side-input-text"><span>Price</span></div>
          <input type="number" v-model="orderFilters.priceMin" oninput="validity.valid||(value='');" min="0" placeholder="Min" class="col-lg-4 col-md-4 col-xs-6 pretty-input">
          <input type="number" v-model="orderFilters.priceMax" min="0" oninput="validity.valid||(value='');" placeholder="Max" class="col-lg-4 col-md-4 col-xs-6 pretty-input rounded-right-corners">
        </div>
      </div>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex flex-row justify-content-end align-items-center">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="ordersTable"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
        <div slot="emptystate" style="display: flex; justify-content: center;">
            <span>Ther are no placed orders at the moment. You can place one <b-link :to="{name:'buy-account'}">here!</b-link></span>
        </div>
        <template
            slot="table-row"
            slot-scope="props"
        >
        <span
          v-if="props.column.field === 'time'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.time.date.split(' ')[0] + '  ' + props.row.time.date.split(' ')[1].slice(0, 5) }}</span>
        </span>
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="status[props.row.status]">
            {{ props.row.status }}
          </b-badge>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButton, BLink, BBadge
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
// import vSelect from 'vue-select'

export default {
  name: 'Transactions',
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BLink,
    BBadge
  },
  data() {
    return {
      orders: [],
      uniqueGroups: [],
      niche: [],
      languages: [],
      orderFilters: {},
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Time',
          field: 'time',
        },
        {
          label: 'Group',
          field: 'group.name',
        },
        {
          label: 'Niche',
          field: 'group.niche',
        },
        {
          label: 'Language',
          field: 'group.language',
        },
        {
          label: 'Geo',
          field: 'group.country',
        },
        {
          label: 'Quantity',
          field: 'quantity',
        },
        {
          label: 'Price',
          field: 'price',
        },
        {
            label: 'Status',
            field: 'status'
        }
      ],
      searchTerm: '',
      status: {'created': 'light-info', 'completed': 'light-success'}
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ordersTable () {
      let newOrders = this.orders
      if (this.orderFilters.languages) {
        newOrders = newOrders.filter(g => g.group.language === this.orderFilters.languages)
      }
      if (this.orderFilters.groups) {
        newOrders = newOrders.filter(g => g.group.id === this.orderFilters.groups.id)
      }
      if (this.orderFilters.priceMin && this.orderFilters.priceMin.length) {
        newOrders = newOrders.filter(g => g.price >= this.orderFilters.priceMin)
      }
      if (this.orderFilters.priceMax && this.orderFilters.priceMax.length) {
        newOrders = newOrders.filter(g => g.price <= this.orderFilters.priceMax)
      }
      return newOrders
    }
  },
  mounted () {
    this.fetchOrders()
  },
  methods: {
    fetchOrders () {
      this.$store.dispatch('fetchOrders')
        .then(() => {
          this.orders = this.$store.state.accountsStore.orders
          this.languages = [... new Set(this.orders.filter(g => g.group.language !== undefined && g.group.language.length > 0).map(g => g.group.language))]
          let allGroups = this.orders.map(g => g.group)
          this.uniqueGroups = Array.from(new Set(allGroups.map(g => g.id))).map(id => {
            return allGroups.find(g => g.id === id)
          })
        })
        .catch(error => {
          let msg = error.message
          if (error.response) {
            msg = error.response.data.msg
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: msg,
              icon: 'InfoIcon',
              variant: 'danger',
            },
          }, {
            position: 'top-center',
          })
        })
    }
  }
}
</script>
